<template>
    <div class="applyForWallet-main">
        <div class="box-h"></div>
        <div class="wallet-from">
            <div class="from-item">
                <span class="lable">{{$t("myWallet.wailetTo")}}</span>
                <p class="select-box" @click="TypeShow = true">
                    <span class="txt">{{payMethod==129002?$t("myWallet.wx"):$t("myWallet.depositCard")}}</span>
                    <i class="Wx-icon" v-if="payMethod==129002"></i>
                    <i class="bank-icon" v-else></i>
                    <i class="icon"></i>
                </p>
            </div>
            <div class="from-item" v-if="payMethod == 129001">
                <span class="lable">{{$t("myWallet.wailetName")}}</span>
                <van-field v-model="bankUsername" :maxlength="5" input-align="right" :placeholder='$t("myWallet.wailetNamePlease")' />
            </div>
            <div class="from-item" v-if="payMethod == 129001">
                <span class="lable">{{$t("myWallet.cardNumber")}}</span>
                <van-field v-model="bankCard" type="number" input-align="right" :placeholder='$t("myWallet.cardNumberPlease")' />
            </div>
        </div>
        <div class="box-h"></div>
        <div class="withdraw-price wallet-from">
            <div class="from-item">
                <span class="lable">{{$t("myWallet.wailetMoney")}}</span>
            </div>
            <div class="price">
                <span class="icon">{{$t("comonTxt.moneyunit")}}</span>
                <span class="price-txt">{{withDrawData.allPrice}}</span>
            </div>
        </div>
        <div class="submit-box">
            <p class="box-btn" @click="submitFun">{{$t("myWallet.applyWailet")}}</p>
            <!-- <p class="share-txt">
                提现金额太少啦？ <span>发给好友一起赚</span>
            </p> -->
        </div>
        <van-overlay v-show="TypeShow" class-name="picketDate">
            <div class="null" @click="TypeShow=false"></div>
            <van-picker
                :title='$t("myWallet.wailetType")'
                show-toolbar
                :columns="columns"
                @confirm="onConfirm"
                @cancel="TypeShow=false"
            />
        </van-overlay>
    </div>
</template>

<script>
import { getWxUserOpenId, withdraw } from "@/api/shop";
export default {
    data(){
        return{
            TypeShow:false,
            openId:"",
            payMethod:129002,
            bankUsername:"",
            bankCard:"",
            withDrawData:{},
            // userMoneys
            columns:[this.$t("myWallet.wx"),this.$t("myWallet.depositCard")]
        }
    },
    created(){
        this.withDrawData = this.$route.query;
        console.log(this.withDrawData);
        if(!(this.withDrawData&&this.withDrawData.userMoneys instanceof Array)){
            this.withDrawData.userMoneys = [this.withDrawData.userMoneys];
        }

        this.getOpenId();
    },
    methods:{
        async getOpenId(){
            let datadd = {};
            let result = await getWxUserOpenId(datadd);
            if(result.data.errorCode == 0){
                this.openId = result.data.data;
            }
        },
        onConfirm(e){
            // console.log(e);
            this.payMethod = e== this.$t("myWallet.wx") ?129002:129001;
            this.TypeShow = false;
        },
        async submitFun(){
            if(this.payMethod == 129001){
                if(!this.bankUsername){
                    this.$toast(this.$t("myWallet.wailetNamePlease"))
                    return false;
                }
                if(!this.bankCard){
                    this.$toast(this.$t("myWallet.cardNumberPlease"))
                    return false;
                }
            }
            // if(!this.openId ){
            //     this.$toast("系统错误，申请失败!")
            //     return false;
            // }
            let datadd = {
                // wxOpenid:this.openId,
                userMoneys:this.withDrawData.userMoneys,
                bankUsername:this.bankUsername,
                bankCard:this.bankCard,
                payMethod:this.payMethod
            }
             const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await withdraw(datadd);
            toast.close();
            if(result.data.errorCode == 0){
                this.$toast(this.$t("comonTxt.operationsuccess"));
                this.$router.go(-1);
            }else{
                this.$toast(this.$t("comonTxt.operationfail"));
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.applyForWallet-main{
    height: 100%;
    background: #F5F6FA;
    .box-h{
        height: .4rem;
    }
    .wallet-from{
        background: #fff;
        .from-item{
            padding: 0 .6rem;
            display: flex;
            align-items: center;
            border-bottom: .02rem solid #F5F6FA;
            height: 2rem;
            &:last-child{
                border: 0;
            }
            .lable{
                flex: auto 0 0;
                font-size: .64rem;
                font-weight: 400;
                color: #303133;
                margin-right: .3rem;
            }
            .van-field{
                font-size: .56rem;
                text-align: right;
                padding: 0;
            }
            .select-box{
                flex: 1;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .txt{
                    font-size: .52rem;
                    font-weight: 400;
                    color: #303133;
                }
                .Wx-icon{
                    width: .54rem;
                    height: .5rem;
                    margin-left: .2rem;
                    background: url("~@/assets/img/WeChat-icon.png") no-repeat;
                    background-size: 100% 100%;
                }
                .bank-icon{
                    width: .68rem;
                    height: .5rem;
                    margin-left: .2rem;
                    background: url("~@/assets/img/depositCard-icon.png") no-repeat;
                    background-size: 100% 100%;
                }
                .icon{
                    width: .26rem;
                    height: .4rem;
                    background: url("~@/assets/img/more-icon.png") no-repeat;
                    background-size: 100% 100%;
                    margin-left: .4rem;
                }
            }
        }
    }
    .withdraw-price{
        overflow: hidden;
        .from-item{
            border: 0;
        }
        .price{
            padding: 0 .6rem;
            border-bottom: .02rem solid #F5F6FA;
            margin-bottom: .6rem;
            .icon{
                font-size: .76rem;
                font-weight: 500;
                margin-right: .3rem;
                color: #303133;
            }
            .price-txt{
                font-size: 1.16rem;
                font-weight: 500;
                color: #303133;
            }
        }
    }
    .submit-box{
        padding: 0 .6rem;
        margin-top: 1.8rem;
        .box-btn{
            height: 1.92rem;
            line-height: 1.92rem;
            text-align: center;
            border-radius: .96rem;
            font-size: .64rem;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(180deg, #579CFF 0%, #3186F8 100%);
        }
        .share-txt{
            font-size: .52rem;
            font-weight: 400;
            color: #838385;
            text-align: center;
            margin-top: 1rem;
            span{
                color: #3F8EFA;
            }
        }
    }
}
.picketDate{
    display: flex;
    flex-direction: column;
    .null{
        flex: 1;
    }
    .van-picker {
        flex: auto 0 0;
    }
}
</style>
